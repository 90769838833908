<template>
  <div>
    <section class="popular-cities">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="section-title">{{ $t('home.mostPopularCities') }}</h2>
            <p>{{ $t('home.bookSmart') }}</p>
          </div>
        </div>

        <div class="row images-blocks">
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="/listing?latitude=52.52000659999999&longitude=13.404954&destination=Berlin" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 24.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Berlin</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="/listing?latitude=49.0068901&longitude=8.4036527&destination=Karlsruhe" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 25.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Karlsruhe</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="/listing?latitude=50.1109221&longitude=8.6821267&destination=Frankfurt%20am%20Main" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 26.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Frankfurt am main</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="/listing?latitude=50.937531&longitude=6.9602786&destination=Colonge" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 27.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Colonge</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="/listing?latitude=51.2230411&longitude=6.7824545&destination=Dusseldorf" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 28.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Dusseldorf</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="/listing?latitude=51.0504088&longitude=13.7372621&destination=Dresden" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 29.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Dresden</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="/listing?latitude=52.3758916&longitude=9.732010400000002&destination=Hannover" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 30.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Hannover</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="/listing?latitude=51.3118828&longitude=9.4915751&destination=Kassel" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 31.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Kassel</h4>
            </a>
          </div>
          <div class="col-md-4 col-lg-4 col-6 mob-padding">
            <a href="/listing?latitude=48.5216364&longitude=9.0576448&destination=Tubinger" class="img-outer position-relative">
              <img
                src="../assets/images/Frame 32.png"
                alt="hello"
                class="img-fluid"
                width="100%"
              />
              <h4 class="pos-title">Tubinger</h4>
            </a>
          </div>
        </div>

        <div class="row miss-out">
          <div class="col-12 text-center">
            <h2 class="section-title">{{ $t('home.dontMissOut') }}</h2>
            <p>{{ $t('home.incredibleOffer') }}</p>
            <div class="great-offers">
              <b-form inline>
                <b-input-group>
                  <b-form-input
                    id="inline-form-input-name"
                    class="banner-input-rule"
                    :placeholder="$t('home.yourEmail')"
                  ></b-form-input>
                  <b-button variant="primary" class="find"
                    >{{ $t('home.getUpdatesOnOffers') }}</b-button
                  >
                </b-input-group>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="section-title color-fff">{{ $t('home.aboutGloveler') }}</h2>
            <p>{{ $t('home.incredibleOffer') }}</p>
          </div>
        </div>
        <div class="row about-inner">
          <div class="col-md-6 col-lg-6 block-outer">
            <b-card
              :title="$t('home.whatIsGloveler')"
              img-src="../assets/images/Frame 35.png"
              img-alt="Image"
              img-top
              tag="article"
            >
              <b-card-text>
                {{ $t('home.whatIsGlovelerContent') }}
              </b-card-text>

              <b-button href="#">{{ $t('home.learnMore') }}</b-button>
            </b-card>
          </div>
          <div class="col-md-6 col-lg-6 block-outer">
            <b-card
              :title="$t('home.listForFree')"
              img-src="../assets/images/Frame 39.png"
              img-alt="Image"
              img-top
              tag="article"
            >
              <b-card-text>
                {{ $t('home.listForFreeContent') }}
              </b-card-text>

              <b-button href="#">{{ $t('home.listAProperty') }}</b-button>
            </b-card>
          </div>
        </div>
      </div>
    </section>

    <section class="why-glovier">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="section-title color-444">{{ $t('home.whyGloveler') }}</h2>
            <p>{{ $t('home.incredibleOffer') }}</p>
          </div>
        </div>

        <div class="row tick-outer">
          <div class="col-md-4 text-left">
            <span><img src="../assets/images/tick.png" /></span>
            <h4 class="card-title">{{ $t('home.cheaperThanHotel') }}</h4>
            <p class="card-text">
              {{ $t('home.cheaperThanHotelContent') }}
            </p>
          </div>
          <div class="col-md-4 text-left">
            <span><img src="../assets/images/tick.png" /></span>
            <h4 class="card-title">{{ $t('home.inTheThick') }}</h4>
            <p class="card-text">
              {{ $t('home.inTheThickContent') }}
            </p>
          </div>
          <div class="col-md-4 text-left">
            <span><img src="../assets/images/tick.png" /></span>
            <h4 class="card-title">
              {{ $t('home.thousandsOfAccomodation') }}
            </h4>
            <p class="card-text">
              {{ $t('home.thousandsOfAccomodationContent') }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="logos-sec">
      <div class="container-fluid plr-80 text-center">
        <ul class="d-flex">
          <li><img src="../assets/images/brand 1.png" alt="" /></li>
          <li><img src="../assets/images/european-union 1.png" alt="" /></li>
          <li>
            <img src="../assets/images/n-tv-seeklogo.com-2 1.png" alt="" />
          </li>
          <li>
            <img
              src="../assets/images/wirtschaftswoche-seeklogo.com 1.png"
              alt=""
            />
          </li>
          <li>
            <img src="../assets/images/1598px-VDIVDE-IT_Logo 1.png" alt="" />
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "mainSec",
  components: {},
};
</script>
<style >
</style>