<template>
  <div>
    <HeroSection></HeroSection>
    <MainSec></MainSec>
  </div>
</template>
<script>
import HeroSection from "../components/HeroSection.vue";
import MainSec from "../components/MainSec.vue";

export default {
  name: "hotel",
  components: {
    MainSec,
    HeroSection,
  },
};
</script>
